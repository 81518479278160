<template>
    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6">

            <div class="h-16 flex flex-row">

                <div class="h-full w-10/12 flex flex-row">

                    <div class="h-full w-2/6 flex flex-row justify-center items-center">
                        <div class="h-12 w-12 rounded-full bg-white" :style="'background-image: url(/img/'+ 'user' +'.JPG); background-repeat: no-repeat; background-size: cover'"></div>
                    </div>

                    <div class="h-full w-4/6 flex flex-col justify-center items-start">
                        <span class="text-2xl text-darkgreen">Orin Paudeck</span>
                        <span class="text-xs text-gray">{{$t('zoneName')}}</span>
                    </div>

                </div>

                <div class="h-full w-2/12 flex flex-col justify-start items-end">
                    <i class="mdi mdi-dots-vertical text-gray text-xl"></i>
                </div>

            </div>

            <div class="h-auto px-2 mt-6">

            <div class="h-auto flex flex-row justify-start items-center">
                <span class="text-xl text-darkgreen mr-1">Rankings</span>
                <span class="text-xl text-purple mr-1 font-medium">{{$t('families')}}</span>
            </div>

            <div v-for="(el,index) in 10" :key="index" class="h-32 rounded-lg bg-lightgray mt-6 flex flex-col relative">

                <i class="mdi mdi-trophy-outline text-gold absolute top-0 right-0 mr-2 text-3xl"></i>

                <div class="h-auto py-1 flex flex-row justify-start items-center px-4">
                    <span class="text-lg text-darkgreen">{{$t('familiyName')}}</span>
                </div>

                <div class="flex-1 flex flex-row py-1">

                    <div class="h-full w-1/2 flex flex-col justify-between items-center">

                        <span class="text-md text-gray font-medium">{{$t('theBest')}}</span>
                        <div class="h-8 w-8 rounded-full" :style="'background-image: url(/img/'+ 'user' +'.JPG); background-repeat: no-repeat; background-size: cover'"></div>
                        <span class="text-md font-medium" :class="'text-font_gray-'+mode">11984€</span>

                    </div>

                    <div class="h-full w-1/2 flex flex-col justify-between items-center">

                        <span class="text-md text-gray font-medium">{{$t('yourPosition')}}</span>
                        <span class="text-xl text-darkgreen font-bold">1º</span>
                        <span class="text-md font-medium" :class="'text-font_gray-'+mode">11984€</span>

                    </div>


                </div>

            </div>

            </div>

            <div class="h-6"></div>

        </div>

        <div class="h-6"></div>

    </div>
</template>

<script>
    import { state } from '@/store';
    import solidgauge from '../../components/solidgauge.vue';

    export default {
        name: 'representante',
        computed: {
            user() {
                return state.user;
            },
        },
        components:{
            solidgauge
        }
    }
</script>